@for (toast of toasts$ | async; track toast.id) {
  <ngb-toast
    class="text-light"
    [header]="toast.status === 'success' ? 'Erfolg!' : 'Fehler!'"
    [autohide]="true"
    [delay]="5000"
    [ngClass]="{
      'bg-success': toast.status === 'success',
      'bg-danger': toast.status === 'error',
    }"
    (hidden)="closeToast(toast.id)"
  >
    {{ toast.message }}
  </ngb-toast>
}
