import { ROUTES } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { LOCALE_ID, importProvidersFrom, ApplicationConfig } from '@angular/core';

import { provideCloudinaryLoader, registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import localeDeCh from '@angular/common/locales/de-CH';
import { provideAppCheck, initializeAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';

const firebaseOptions = {
  apiKey: 'AIzaSyBYw7S79Av5X3_xhvWjIE8oLtC-kG8VJAI',
  authDomain: 'lumiron-bubblesoccer.firebaseapp.com',
  databaseURL: 'https://lumiron-bubblesoccer-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'lumiron-bubblesoccer',
  storageBucket: 'lumiron-bubblesoccer.appspot.com',
  messagingSenderId: '990437382583',
  appId: '1:990437382583:web:5956b070c476eef3b97032',
  measurementId: 'G-G89Y7PD3RZ',
};

registerLocaleData(localeDeCh);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      ROUTES,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
    ),
    { provide: FIREBASE_OPTIONS, useValue: firebaseOptions },
    { provide: LOCALE_ID, useValue: 'de-CH' },
    provideHttpClient(),
    provideCloudinaryLoader('https://res.cloudinary.com/lumiron'),
    // provideExperimentalZonelessChangeDetection(),
    provideFirebaseApp(() => initializeApp(firebaseOptions)),
    provideFirestore(() => {
      const firestore = getFirestore();

      if (!environment.production) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }

      return firestore;
    }),
    importProvidersFrom(),
    provideAppCheck(() =>
      initializeAppCheck(getApp(), {
        provider: new ReCaptchaV3Provider('6Lf0XbcfAAAAACMMNRs28Gu4gEDvSQ_M35Siq8gE'),
        isTokenAutoRefreshEnabled: true,
      }),
    ),
    provideAnimations(),
  ],
};
