import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ToastService } from './toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lumiron-toasts',
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgbToastModule],
})
export class ToastsComponent {
  private readonly toastService = inject(ToastService);
  protected readonly toasts$ = this.toastService.getToasts();

  protected closeToast(id: number): void {
    this.toastService.closeToast(id);
  }
}
