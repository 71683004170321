import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

const resolveRouteTitle = (title: string) =>
  `${title} | Lumiron - Bubble Soccer`;

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    title: resolveRouteTitle('Home'),
    component: HomeComponent,
  },
  {
    path: 'angebot',
    title: resolveRouteTitle('Angebot'),
    loadComponent: () =>
      import('./offer/offer.component').then(
        (module_) => module_.OfferComponent,
      ),
  },
  {
    path: 'buchung',
    title: resolveRouteTitle('Buchung'),
    loadComponent: () =>
      import('./booking/booking.component').then(
        (module_) => module_.BookingComponent,
      ),
  },
  {
    path: 'ueber-uns',
    title: resolveRouteTitle('Über uns'),
    loadComponent: () =>
      import('./about/about.component').then(
        (module_) => module_.AboutComponent,
      ),
  },
  {
    path: 'kontakt',
    title: resolveRouteTitle('Kontakt'),
    loadComponent: () =>
      import('./contact/contact.component').then(
        (module_) => module_.ContactComponent,
      ),
  },
  {
    path: 'impressum',
    title: resolveRouteTitle('Impressum'),
    loadComponent: () =>
      import('./imprint/imprint.component').then(
        (module_) => module_.ImprintComponent,
      ),
  },
  {
    path: 'agb',
    title: resolveRouteTitle('AGB'),
    loadComponent: () =>
      import('./agb/agb.component').then((module_) => module_.AgbComponent),
  },
  { path: '**', redirectTo: 'home' },
];
