import { CommonModule, ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './shared/footer/footer.component';
import { NavComponent } from './shared/nav/nav.component';
import { ToastsComponent } from './shared/toasts/toasts.component';
import { NavItem } from './shared/nav/nav';

@Component({
  selector: 'lumiron-app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    NavComponent,
    FooterComponent,
    ToastsComponent,
  ],
})
export class AppComponent {
  private readonly viewportScroller = inject(ViewportScroller);

  protected readonly navItems: readonly NavItem[] = [
    {
      label: 'Angebot',
      link: 'angebot',
    },
    // {
    //   label: 'Ablauf',
    //   link: 'ablauf',
    // },
    {
      label: 'Über uns',
      link: 'ueber-uns',
    },
  ];

  constructor() {
    this.viewportScroller.setOffset([0, 70]);
  }
}
